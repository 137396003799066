<template>
  <div>
    <h1 class="page-header">Share on Instagram</h1>
    
    <div v-if="isIOS">
      <div :bind="step" class="pb-6 font-weight-medium" v-html="steps[step]">
      </div>
      <div v-show="step === 0" class="space-y-3">
        <base-input ref="textarea-container" inputGroupClasses="textarea-container" alternative>
          <textarea
            ref="caption"
            class="form-control simple"
            rows="3"
            placeholder="Write a caption here..."
            v-model="caption"></textarea>
        </base-input>
        <base-button 
          href="javascript:;"
          type="primary"
          class="btn text primary"
          @click="copyTextArea()">
          <span>Copy</span>
        </base-button>
      </div>
      <div v-show="step === 1" class="space-y-3">
        <div class="overlay" v-if="isImageLoading">
          <div class="loader">
            <span class="loading"><inline-svg src="/img/icons/loading.svg"></inline-svg></span>
          </div>
        </div>
        <img ref="image" :src="file ? tweetshotURL : '/img/tweetshot_placeholder_blank.png'" class="w-full">
        <base-button 
          href="javascript:;"
          type="primary"
          class="btn text primary"
          @click="downloadImage()">
          <span>Download Image</span>
        </base-button>
      </div>
      <div v-show="step === 2" class="space-y-3">
        <div>The image has been downloaded.</div>
        <div>Click on the download button on the top right of the screen, then share it to the Instagram app!</div>
        <img class="w-12 h-12 mx-auto" src="/img/ios-download-icon.png"/>
        <div>Remember that the caption is saved in your clipboard.</div>
      </div>
    </div>

    <div v-else>
      <div class="space-y-6">
        <div class="relative">
          <div class="overlay" v-if="isImageLoading">
            <div class="loader">
              <span class="loading loading"><inline-svg src="/img/icons/loading.svg"></inline-svg></span>
            </div>
          </div>
          <img ref="image" :src="file ? tweetshotURL : '/img/tweetshot_placeholder_blank.png'" class="img-thumbnail w-100">
        </div>
        <base-input ref="textarea-container" inputGroupClasses="textarea-container" alternative>
          <textarea
            data-cy="caption-textarea"
            ref="caption"
            class="form-control simple"
            rows="3"
            placeholder="Write a caption here..."
            v-model="caption"></textarea>
        </base-input>
        <base-button
          data-cy="share-on-instagram-button"
          href="javascript:;"
          type="primary"
          class="btn text primary"
          @click="shareToInstagram()">
          <span>Share on Instagram</span>
        </base-button>
      </div>
    </div>

  </div>
</template>
<script>
  import controller from '@/controller';
  import dao from '@/dao';
  import { HalfCircleSpinner } from 'epic-spinners'
  import lodash from 'lodash';
  import MobileDetect from 'mobile-detect';
  import SwalModalMixin from '@/views/Mixins/SwalModalMixin.vue';
  import { mapGetters } from 'vuex';
  import saveAs from 'file-saver';
  import storage from '@/storage';
  const fb = require('@/firebase');

  const config = require('@/config');

  const defaultCaption = 'Write a caption here...\n' +
    '•\n' +
    '•\n' +
    '•\n' +
    '•\n' +
    '•\n' +
    '#hypefury #twitterquotes #twitter ';

  export default {
    data() {
      return {
        tweetshotURL: '',
        caption: '',
        file: null,
        isImageLoading: true,
        isIOS: false,
        steps: [
          'Step 1: Edit and copy the caption',
          'Step 2: Download the tweetshot',
          `Step 3: Share it on Instagram`,
        ],
        step: 0,
      };
    },
    components: {
      HalfCircleSpinner,
    },
    computed: {
      ...mapGetters({ currentUser: 'getCurrentUser', userProfile: 'getUserProfile' }),
    },
    created() {
      const md = new MobileDetect(navigator.userAgent);
      this.isIOS = md.is('iOS');

      const postId = this.$route.params.threadId;
      const userId = this.$route.params.userId;

      this.getTweetshot(postId, userId);
    },
    methods: {
      adjustHeightOfTextarea() {
        this.$nextTick(() => {
          this.$refs.caption.style.height = '';
          this.$refs.caption.style.height = (this.$refs.caption.scrollHeight + 2) + 'px';
        });
      },
      copyTextArea() {
        this.$refs.caption.select();
        document.execCommand('copy');
        if (window.getselection) {
          if (window.getselection().empty) {  // chrome
            window.getselection().empty();
          } else if (window.getselection().removeallranges) {  // firefox
            window.getselection().removeallranges();
          }
        } else if (document.selection) {  // ie?
          document.selection.empty();
        }
        const message = 'Caption copied to clipboard!';
        this.$notify({type: 'info', message});
        this.saveCaption();
        this.step++;
      },
      displayImage(blob, url) {
        this.tweetshotURL = url;
        this.file = new File([blob], 'tweetshot.jpg', {type: 'image/jpeg'});
        setTimeout(() => this.isImageLoading = false, 1000);
      },
      downloadImage() {
        const url = window.URL.createObjectURL(this.file);
        saveAs(url, "tweetshot.jpg");
        this.step++;
      },
      async getTweetshot(postId, userId) {
        const tweetshotName = `tweetshots/${userId}/${postId}-0.jpg`;
        const url = config.buildStorageMediaURL(tweetshotName);
        const doesTweetshotExist = await storage.doesTweetshotExist(userId, postId);
        if (doesTweetshotExist) {
          fetch(url).then(response => {
            return response.blob().then(blob => {
              this.displayImage(blob, url);
            })
          });
        } else {
          controller.generateTweetshot(this.currentUser, postId);
          const unsubscribe = fb.threadsCollection.doc(postId).onSnapshot((doc) => {
            if (doc.data().tweetshots) {
              fetch(url).then(response => {
                return response.blob().then(blob => {
                  this.displayImage(blob, url);
                })
              });
              unsubscribe();
            }
          });
        }
      },
      saveCaption() {
        dao.integrations.saveCaption(this.userProfile.uid, this.caption)
          .catch(error => {
            console.error(error);
          });
      },
      shareToInstagram() {
        this.copyTextArea();
        const toShare = {
          files: [this.file],
          title: 'tweetshot.jpg',
          text: this.caption,
        };
        if (navigator.share) {
          navigator.share(toShare)
            .catch(error => console.error(error));
        } else {
          const message = 'Please use your phone to share on Instagram.';
          this.swalModal({
            title: 'Warning',
            text: message,
            type: 'warning',
          });
        }
      },
    },
    mounted() {
      const userInstagramCaption = lodash.get(this.userProfile, 'integrations.instagram.caption');
      if (userInstagramCaption) {
        this.caption = userInstagramCaption;
      } else {
        this.caption = defaultCaption;
        this.$nextTick(() => this.$refs.caption.setSelectionRange(0, 23));
      }
      this.$refs.caption.focus();
      this.adjustHeightOfTextarea();
    },
    watch: {
      caption(val, oldVal) {
        this.adjustHeightOfTextarea();
      },
    },
    mixins:[SwalModalMixin]
  }
</script>
